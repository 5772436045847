import React from "react";
import styles from "../styles/Card.module.css";
import { Skeleton } from "@mui/material";

const LeaguesSkeleton = () => {
  return (
    <div className={styles.card_container}>
      {Array.from({ length: 3 }).map((_, index) => (
        <div key={`tour_skeleton_${index}`} className={styles.card}>
          <div className={styles.card_header} style={{ padding: "6px 0" }}>
            <Skeleton
              variant="text"
              width={150}
              height={24}
              sx={{ bgcolor: "grey.900" }}
            />
          </div>
          <div className={styles.card_content_container}>
            <div className={styles.match_date} style={{ marginTop: "8px" }}>
              <Skeleton
                variant="text"
                width={100}
                height={20}
                sx={{ bgcolor: "grey.900" }}
              />
            </div>
            {Array.from({ length: 3 }).map((_, idx) => (
              <div
                key={`match_skeleton_${idx}_${index}`}
                className={styles.skeleton_container}
              >
                <Skeleton
                  className={styles.centered_skeleton}
                  width="96%"
                  height={32}
                  sx={{ bgcolor: "grey.900" }}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default LeaguesSkeleton;
